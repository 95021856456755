<template>
  <div class="input-popup">
    <input ref="inputtag" :placeholder="computedPlaceholder" type="text" v-model.trim="inputValue" @click="handleInputFocus"
      @paste="pasteFn" @keydown.delete.stop="removeLastTag" @keyup.stop="keyupFn" @blur="handleInputBlur" @focus="handleInputFocus"
      class="new-tag" />
    <transition name="el-zoom-in-top">
      <div class="contact-list-popup" :style="position" v-show="visible">
        <div class="popper-arrow"></div>
        <div class="contact-header">按ESC键关闭菜单</div>
        <div class="contact-list" ref="contactContainer">
          <div class="contact-item" v-for="(item, index) in contactList" :key="index" @click="selectContact(index)" :class="{'active-contact-item': activeIndex === index}">
            <el-avatar :size="26" style="margin-right: 18px;" :style="{background: bgColor(index)}"> {{item.mailName[0]}} </el-avatar>
            <div class="contact-info">
              <span v-if="item.mailName.indexOf(inputValue) !== -1">
                {{item.mailName.substr(0, item.mailName.indexOf(inputValue))}}
                <span style="color: #ffad2c;">{{inputValue}}</span>
                {{item.mailName.substr(item.mailName.indexOf(inputValue) + inputValue.length)}}
              </span>
              <span v-else>{{item.mailName}}</span>

              (<span v-if="item.mailAccount.indexOf(inputValue) !== -1 && item.mailName.indexOf(inputValue) === -1">
                {{item.mailAccount.substr(0, item.mailAccount.indexOf(inputValue))}}
                <span style="color: #ffad2c;">{{inputValue}}</span>
                {{item.mailAccount.substr(item.mailAccount.indexOf(inputValue) + inputValue.length)}}
              </span>
              <span v-else>{{item.mailAccount}}</span>)
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
/* eslint-disable max-len */
import { getOffset, debounce } from "../../../assets/js/utils";

function scrollIntoView(container, selected) {
  if (!selected) {
    container.scrollTop = 0;
    return;
  }
  const offsetParents = [];
  let pointer = selected.parentNode;
  while (pointer && container !== pointer && container.contains(pointer)) {
    offsetParents.push(pointer);
    pointer = pointer.offsetParent;
  }
  const top = selected.offsetTop + offsetParents.reduce((prev, curr) => (prev + curr.offsetTop), 0);
  const bottom = top + selected.offsetHeight;
  const viewRectTop = container.scrollTop;
  const viewRectBottom = viewRectTop + container.clientHeight;

  if (top < viewRectTop) {
    container.scrollTop = top;
  } else if (bottom > viewRectBottom) {
    container.scrollTop = bottom - container.clientHeight;
  }
}

export default {
  name: "InputPopup",
  props: {
    computedPlaceholder: {
      type: String,
      default: ""
    },
    value: {
      type: String,
      default: ""
    },
    contactList: {
      type: Array,
      default() {
        return [
          // {
          //   avatarAddr: "#996d56", mailAccount: "apple@enfry.com", mailName: "苹果账号", email: "apple@enfry.com"
          // }
        ];
      }
    },
    // y轴偏移
    offsetY: {
      type: [Number, String],
      default: 0
    }
  },
  computed: {
    inputValue: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    visible() {
      return this.popupVisible && this.contactList.length;
    }
  },
  watch: {
    inputValue(newVal) {
      this.changeValue(newVal);
    }
  },
  data() {
    return {
      popupVisible: false,
      activeIndex: 0,
      position: {
        left: 0,
        top: 0
      }
    };
  },
  methods: {
    bgColor(index) {
      const color = ["#3e90fe", "#26c393", "#ffad2c", "#f76b6b"];
      return color[index % 4];
    },
    // eslint-disable-next-line func-names
    changeValue: debounce(function(v) {
      this.$emit("changeValue", v);
    }, 300),
    selectContact(index) {
      this.activeIndex = index;
      this.inputValue = "";
      this.$emit("confirmSelect", this.contactList[this.activeIndex]);
    },
    pasteFn(e) {
      this.$emit("pasteFn", e);
    },
    removeLastTag() {
      this.$emit("removeLastTag");
    },
    addNew(e) {
      this.$emit("addNew", e);
    },
    handleInputBlur(e) {
      setTimeout(() => {
        if (this.$refs.inputtag !== document.activeElement) {
          this.popupVisible = false;
          this.$emit("handleInputBlur", e);
        }
      }, 300);
    },
    handleInputFocus(e) {
      const position = getOffset(e.target);
      this.position = {
        top: `${position.top + 32 + this.offsetY}px`,
        left: `${position.left - 6}px`
      };
      this.popupVisible = true;
      this.$emit("handleInputFocus", e);
    },
    keyupFn(e) {
      console.log(e);
      const { keyCode } = e;
      if (keyCode === 16 || keyCode === 17) {
        this.$emit("changeKeyDownStatus", keyCode);
      }
      if (!this.visible && keyCode !== 37) {
        this.$emit("addNew", e);
        return;
      }
      const contactLength = this.contactList.length;
      const scrollContainerDom = this.$refs.contactContainer;
      switch (keyCode) {
      case 38:
        // up
        if (this.activeIndex > 0) {
          this.activeIndex--;
        } else {
          this.activeIndex = contactLength - 1;
        }
        break;
      case 40:
        // down
        if (this.activeIndex < contactLength - 1) {
          this.activeIndex++;
        } else {
          this.activeIndex = 0;
        }
        break;
      case 13:
        // enter
        // this.popupVisible = false;
        if (this.$parent.$parent.editIndex === -1) {
          this.$nextTick(() => {
            this.handleInputFocus(e);
          });
          this.$emit("confirmSelect", this.contactList[this.activeIndex]);
        }
        break;
      case 27:
        // esc
        this.popupVisible = false;
        break;
      default:
        break;
      }

      this.$nextTick(() => {
        const activeContactDom = scrollContainerDom.querySelector(".active-contact-item");
        scrollIntoView(scrollContainerDom, activeContactDom);
      });
    }
  }
};
</script>

<style lang="scss" scope>
.input-popup {
  .contact-list-popup {
    position: fixed;
    z-index: 100;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.1);
    width: 500px;
    background-color: #fff;
    border: 1px solid rgba(126,134,142,.16);
    border-radius: 4px;
    .popper-arrow {
      position: absolute;
      border-color: transparent;
      border-style: solid;
      border-width: 6px;
      filter: drop-shadow(0 2px 12px rgba(0,0,0,.03));
      top: -6px;
      left: 12px;
      margin-right: 3px;
      border-top-width: 0;
      border-bottom-color: rgba(126,134,142,.16);
      &::after {
        content: " ";
        position: absolute;
        display: block;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
        border-width: 6px;
        top: 1px;
        margin-left: -6px;
        border-top-width: 0;
        border-bottom-color: #fff;
      }
    }
    .contact-header {
      line-height: 48px;
      color: rgba(23,26,29,1);
      font-size: 14px;
      padding-left: 20px;
      border-bottom: 1px solid rgba(126,134,142,.16);
    }
    .contact-list {
      position: relative;
      max-height: 300px;
      overflow: auto;
      .contact-item {
        display: flex;
        align-items: center;
        cursor: pointer;
        height: 50px;
        padding: 0 0 0 20px;
        font-size: 12px;
        &:hover {
          background: #f5f8fc;
        }
        .contact-info {
          p {
            margin: 0;
          }
        }
      }
      .active-contact-item {
        background: #f5f8fc;
      }
    }
  }
}
</style>
