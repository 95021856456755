var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "input-popup" },
    [
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model.trim",
            value: _vm.inputValue,
            expression: "inputValue",
            modifiers: { trim: true },
          },
        ],
        ref: "inputtag",
        staticClass: "new-tag",
        attrs: { placeholder: _vm.computedPlaceholder, type: "text" },
        domProps: { value: _vm.inputValue },
        on: {
          click: _vm.handleInputFocus,
          paste: _vm.pasteFn,
          keydown: function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "delete", [8, 46], $event.key, [
                "Backspace",
                "Delete",
                "Del",
              ])
            )
              return null
            $event.stopPropagation()
            return _vm.removeLastTag.apply(null, arguments)
          },
          keyup: function ($event) {
            $event.stopPropagation()
            return _vm.keyupFn.apply(null, arguments)
          },
          blur: [
            _vm.handleInputBlur,
            function ($event) {
              return _vm.$forceUpdate()
            },
          ],
          focus: _vm.handleInputFocus,
          input: function ($event) {
            if ($event.target.composing) return
            _vm.inputValue = $event.target.value.trim()
          },
        },
      }),
      _c("transition", { attrs: { name: "el-zoom-in-top" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.visible,
                expression: "visible",
              },
            ],
            staticClass: "contact-list-popup",
            style: _vm.position,
          },
          [
            _c("div", { staticClass: "popper-arrow" }),
            _c("div", { staticClass: "contact-header" }, [
              _vm._v("按ESC键关闭菜单"),
            ]),
            _c(
              "div",
              { ref: "contactContainer", staticClass: "contact-list" },
              _vm._l(_vm.contactList, function (item, index) {
                return _c(
                  "div",
                  {
                    key: index,
                    staticClass: "contact-item",
                    class: { "active-contact-item": _vm.activeIndex === index },
                    on: {
                      click: function ($event) {
                        return _vm.selectContact(index)
                      },
                    },
                  },
                  [
                    _c(
                      "el-avatar",
                      {
                        staticStyle: { "margin-right": "18px" },
                        style: { background: _vm.bgColor(index) },
                        attrs: { size: 26 },
                      },
                      [_vm._v(" " + _vm._s(item.mailName[0]) + " ")]
                    ),
                    _c("div", { staticClass: "contact-info" }, [
                      item.mailName.indexOf(_vm.inputValue) !== -1
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.mailName.substr(
                                    0,
                                    item.mailName.indexOf(_vm.inputValue)
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticStyle: { color: "#ffad2c" } }, [
                              _vm._v(_vm._s(_vm.inputValue)),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.mailName.substr(
                                    item.mailName.indexOf(_vm.inputValue) +
                                      _vm.inputValue.length
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(item.mailName))]),
                      _vm._v(" ("),
                      item.mailAccount.indexOf(_vm.inputValue) !== -1 &&
                      item.mailName.indexOf(_vm.inputValue) === -1
                        ? _c("span", [
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.mailAccount.substr(
                                    0,
                                    item.mailAccount.indexOf(_vm.inputValue)
                                  )
                                ) +
                                " "
                            ),
                            _c("span", { staticStyle: { color: "#ffad2c" } }, [
                              _vm._v(_vm._s(_vm.inputValue)),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  item.mailAccount.substr(
                                    item.mailAccount.indexOf(_vm.inputValue) +
                                      _vm.inputValue.length
                                  )
                                ) +
                                " "
                            ),
                          ])
                        : _c("span", [_vm._v(_vm._s(item.mailAccount))]),
                      _vm._v(") "),
                    ]),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }