var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-tag-container",
      class: { "input-tag-container--active": _vm.isInputActive },
    },
    [
      _c(
        "div",
        {
          ref: "tagWrapper",
          staticClass: "vue-input-tag-wrapper",
          class: {
            "read-only": _vm.readOnly,
            "vue-input-tag-wrapper--active": _vm.isInputActive,
          },
          on: { click: _vm.focusNewTag, scroll: _vm.scrollFn },
        },
        [
          _c(
            "draggable",
            _vm._b(
              {
                staticClass: "draggable",
                attrs: { group: "field" },
                on: { add: _vm.tagChange, end: _vm.tagChange },
                model: {
                  value: _vm.innerTags,
                  callback: function ($$v) {
                    _vm.innerTags = $$v
                  },
                  expression: "innerTags",
                },
              },
              "draggable",
              _vm.dragOptions,
              false
            ),
            [
              _vm._l(
                _vm.innerTags.filter((item) => item),
                function (tag, index) {
                  return _c(
                    "span",
                    {
                      key: index,
                      staticClass: "input-tag",
                      class: {
                        "input-tag-active":
                          _vm.selectIndexArr.includes(index) ||
                          _vm.activeIndex === index,
                        "input-tag-error": _vm.validateTag(tag.mailAccount),
                        disabled: _vm.readOnly,
                      },
                      style: _vm.tagStyle,
                      attrs: { "data-index": index },
                      on: {
                        click: function ($event) {
                          return _vm.changeActive(index)
                        },
                      },
                    },
                    [
                      index !== _vm.editIndex
                        ? _c(
                            "el-popover",
                            {
                              attrs: {
                                disabled: "",
                                content: tag.mailAccount,
                                placement: "bottom",
                                trigger: "hover",
                              },
                            },
                            [
                              _c(
                                "span",
                                {
                                  attrs: {
                                    slot: "reference",
                                    title: tag.mailAccount,
                                    "data-index": index,
                                  },
                                  on: {
                                    dblclick: function ($event) {
                                      $event.stopPropagation()
                                      return _vm.changeEdit(index)
                                    },
                                  },
                                  slot: "reference",
                                },
                                [
                                  _vm._v(
                                    _vm._s(tag.mailName || tag.mailAccount)
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                      index === _vm.editIndex
                        ? _c("input", {
                            ref: "input",
                            refInFor: true,
                            staticClass: "edit-tag",
                            attrs: { type: "text" },
                            domProps: { value: tag.mailAccount },
                            on: {
                              click: function ($event) {
                                $event.stopPropagation()
                              },
                              blur: _vm.modifyTag,
                              keydown: function ($event) {
                                $event.stopPropagation()
                              },
                              keyup: [
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "esc",
                                      27,
                                      $event.key,
                                      ["Esc", "Escape"]
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                  return _vm.notModify(tag)
                                },
                                function ($event) {
                                  if (
                                    !$event.type.indexOf("key") &&
                                    _vm._k(
                                      $event.keyCode,
                                      "enter",
                                      13,
                                      $event.key,
                                      "Enter"
                                    )
                                  )
                                    return null
                                  $event.stopPropagation()
                                  return _vm.modifyTag.apply(null, arguments)
                                },
                              ],
                            },
                          })
                        : _vm._e(),
                      !_vm.readOnly
                        ? _c(
                            "a",
                            {
                              staticClass: "remove",
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  $event.stopPropagation()
                                  return _vm.remove(index)
                                },
                              },
                            },
                            [_vm._t("remove-icon")],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  )
                }
              ),
              !_vm.readOnly
                ? _c("input-popup", {
                    ref: "inputPopup",
                    attrs: {
                      "contact-list": _vm.contactList,
                      "offset-y": _vm.offsetY,
                      "computed-placeholder": _vm.computedPlaceholder,
                    },
                    on: {
                      confirmSelect: _vm.confirmSelect,
                      removeLastTag: _vm.removeLastTag,
                      handleInputBlur: _vm.handleInputBlur,
                      addNew: _vm.addNew,
                      pasteFn: _vm.pasteFn,
                      changeValue: _vm.changeValue,
                      changeKeyDownStatus: _vm.changeKeyDownStatus,
                      handleInputFocus: _vm.handleInputFocus,
                    },
                    model: {
                      value: _vm.newTag,
                      callback: function ($$v) {
                        _vm.newTag = $$v
                      },
                      expression: "newTag",
                    },
                  })
                : _vm._e(),
            ],
            2
          ),
          _c(
            "span",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.innerTags.length && _vm.isInputActive,
                  expression: "innerTags.length && isInputActive",
                },
              ],
              staticClass: "del-btn",
              style: {
                top: _vm.scrollTop + "px",
                bottom: -_vm.scrollTop + "px",
              },
              on: { click: _vm.clearFn },
            },
            [
              _c("span", { staticClass: "icon-container" }, [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.clearBtnVisible,
                      expression: "clearBtnVisible",
                    },
                  ],
                  staticClass: "iconfont iconwuliao-shanchu",
                }),
              ]),
            ]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }